// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--2SplN";
export var fauxLink = "styles-module--fauxLink--3iWmb";
export var textWidth = "styles-module--textWidth--2kORl";
export var pageHeader = "styles-module--pageHeader--3AILr";
export var pageTitle = "styles-module--pageTitle--MszTk";
export var pageSubTitle = "styles-module--pageSubTitle--7c83d";
export var unset = "styles-module--unset--3aX3f";
export var resetList = "styles-module--resetList--2ZqJp";
export var tac = "styles-module--tac--32_eK";
export var tal = "styles-module--tal--u9whJ";
export var tar = "styles-module--tar--3jApn";
export var noBoxShadow = "styles-module--no-box-shadow--31rwu";
export var ptn = "styles-module--ptn--2MDTQ";
export var pvn = "styles-module--pvn--3CVbJ";
export var pan = "styles-module--pan--1hKcD";
export var ptxs = "styles-module--ptxs--3WR6P";
export var pvxs = "styles-module--pvxs--2673Z";
export var paxs = "styles-module--paxs--2YTD4";
export var pts = "styles-module--pts--3JMbq";
export var pvs = "styles-module--pvs--2eVtz";
export var pas = "styles-module--pas--2OL-g";
export var ptm = "styles-module--ptm--1ndVw";
export var pvm = "styles-module--pvm--EgzR1";
export var pam = "styles-module--pam--1Cy59";
export var ptl = "styles-module--ptl--3HZ7W";
export var pvl = "styles-module--pvl--2-_UG";
export var pal = "styles-module--pal--2u5U8";
export var ptxl = "styles-module--ptxl--1a0oA";
export var pvxl = "styles-module--pvxl--1nr5o";
export var paxl = "styles-module--paxl--1uTPl";
export var prn = "styles-module--prn--meqVp";
export var phn = "styles-module--phn--i1NJo";
export var prxs = "styles-module--prxs--3bszu";
export var phxs = "styles-module--phxs--1wa7F";
export var prs = "styles-module--prs--2FIdT";
export var phs = "styles-module--phs--W4_wr";
export var prm = "styles-module--prm--1fAi0";
export var phm = "styles-module--phm--3hQlo";
export var prl = "styles-module--prl--37veW";
export var phl = "styles-module--phl--3ki68";
export var prxl = "styles-module--prxl--1-_WR";
export var phxl = "styles-module--phxl--s1Vtq";
export var pbn = "styles-module--pbn--2IAKD";
export var pbxs = "styles-module--pbxs--7Ib-v";
export var pbs = "styles-module--pbs--2iTRI";
export var pbm = "styles-module--pbm--2jEdn";
export var pbl = "styles-module--pbl--3P6ER";
export var pbxl = "styles-module--pbxl--HvPzo";
export var pln = "styles-module--pln--CtnVh";
export var plxs = "styles-module--plxs--2xZCD";
export var pls = "styles-module--pls--24oWm";
export var plm = "styles-module--plm--2TgcG";
export var pll = "styles-module--pll--26eEW";
export var plxl = "styles-module--plxl--of0Bk";
export var mtn = "styles-module--mtn--2F9Fi";
export var mvn = "styles-module--mvn--3jHK3";
export var man = "styles-module--man--3tT1H";
export var mtxs = "styles-module--mtxs--2dJER";
export var mvxs = "styles-module--mvxs--9od3f";
export var maxs = "styles-module--maxs--17KYZ";
export var mts = "styles-module--mts--21Dxt";
export var mvs = "styles-module--mvs--1caWX";
export var mas = "styles-module--mas--bUxgw";
export var mtm = "styles-module--mtm--301Y2";
export var mvm = "styles-module--mvm--16F4z";
export var mam = "styles-module--mam--rxkuI";
export var mtl = "styles-module--mtl--1HWRy";
export var mvl = "styles-module--mvl--2HT_c";
export var mal = "styles-module--mal--2wAXY";
export var mtxl = "styles-module--mtxl--3ERCS";
export var mvxl = "styles-module--mvxl--m1fma";
export var maxl = "styles-module--maxl--3LlYl";
export var mrn = "styles-module--mrn--1eY1f";
export var mhn = "styles-module--mhn--2sUxM";
export var mrxs = "styles-module--mrxs--sf3Pz";
export var mhxs = "styles-module--mhxs--3Fnb0";
export var mrs = "styles-module--mrs--3g02C";
export var mhs = "styles-module--mhs--1anNr";
export var mrm = "styles-module--mrm--2lYN-";
export var mhm = "styles-module--mhm--_WDbR";
export var mrl = "styles-module--mrl--26lGC";
export var mhl = "styles-module--mhl--_6sti";
export var mrxl = "styles-module--mrxl--9hGRr";
export var mhxl = "styles-module--mhxl--2pEf3";
export var mbn = "styles-module--mbn--QiHAx";
export var mbxs = "styles-module--mbxs--U-nad";
export var mbs = "styles-module--mbs--23BuG";
export var mbm = "styles-module--mbm--1qSjH";
export var mbl = "styles-module--mbl--3kOcN";
export var mbxl = "styles-module--mbxl--398Q7";
export var mln = "styles-module--mln--3WLVy";
export var mlxs = "styles-module--mlxs--1bY27";
export var mls = "styles-module--mls--29z90";
export var mlm = "styles-module--mlm--_Cnqt";
export var mll = "styles-module--mll--3_2rB";
export var mlxl = "styles-module--mlxl--GUsC2";
export var intro = "styles-module--intro--2BLHj";
export var headshotWrapper = "styles-module--headshotWrapper--2rIyH";
export var introCopy = "styles-module--introCopy--O1y_X";
export var borderCircleImg = "styles-module--borderCircleImg--1-2NB";
export var headshotImg = "styles-module--headshotImg--3bOev";
export var workedWith = "styles-module--workedWith--2tCAv";
export var logos = "styles-module--logos--15vgr";
export var border = "styles-module--border--1RJ-7";
export var twenty47Logo = "styles-module--twenty47Logo--3aq0n";
export var capsTitle = "styles-module--capsTitle--4Wfyb";
export var workedWithTitle = "styles-module--workedWithTitle--1lVWD";
export var quotes = "styles-module--quotes--DciHF";
export var quote = "styles-module--quote--2feim";
export var quoteLink = "styles-module--quoteLink--17ESt";
export var quoteImgWrapper = "styles-module--quoteImgWrapper--2TzEN";
export var quoteAuthorName = "styles-module--quoteAuthorName--aXaT8";
export var quoteAuthorTitle = "styles-module--quoteAuthorTitle--2qxJU";
export var ctaSection = "styles-module--ctaSection--1DXfX";
export var blogPreview = "styles-module--blogPreview--2e6nL";
export var blogHeader = "styles-module--blogHeader--yWpHj";
export var blogTitle = "styles-module--blogTitle--1iSiX";
export var viewAllLink = "styles-module--viewAllLink--25OzS";